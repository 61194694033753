<template>
    <!-- eslint-disable -->
    <div class="w-100">
      <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">    
        <b-card no-body>
          <b-card-body>
            <div class="h_iframe">
                    <iframe title="restricciones_v2" style="width: 90vw; height: 90vh;" :src="url_powerBi" frameborder="0" allowFullScreen></iframe>     
                </div>
          </b-card-body>
        </b-card>    
      </b-overlay>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import Vue from 'vue' 
  import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import RestrictionsService from '@/services/RestrictionsService'
  import OwnerService from '@/services/OwnerService'
  import ProjectsService from '@/services/ProjectsService'
  import LocalizationService from '@/services/LocalizationService'
  import SpecialityService from '@/services/SpecialityService'
  import CategoryService from '@/services/CategoryService'
  import QueryService from '@/services/QueryService'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import { required } from '@validations'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import moment from 'moment'
  const APIURL = process.env.APIURLFILE
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  export default {
    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
    data() {
      return {
        name: '',
        status: '',
        statusFilter: '',
        required,
        show: false,
        apiurl: APIURL,
        showLoading: false, 
        dueno_id: null,
        duenos: [],   
        proyectos:[],
        currentPage: 1,
        project_id: JSON.parse(localStorage.getItem('project_id')),
        sort: 'id',
        order: 'desc',
        url_powerBi:""
      }
    },
    components: {
      vSelect,
      flatPickr,     
      AppCollapse,
      AppCollapseItem,
      AppTimeline,
      ValidationProvider,
      ValidationObserver,
      AppTimelineItem
    },
    computed: {
     
    },
    mounted() {
      this.getProjects();
    },
    methods: {
      async getProjects(){
        const url = `?limit=100&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`
        const respProyectos = await ProjectsService.getProyectos(url, this.$store)
        console.log('respProyectos', respProyectos)
        if (respProyectos.status) {
          this.proyectos = respProyectos.data.rows
          let project = respProyectos.data.rows.find((x)=> x.id == this.project_id)
          if(project){
            this.url_powerBi = project.url_powerbi
          }
          console.log({project})
        }
      },
     
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
   .h_iframe{
    text-align: center;
   }
  .pad-export {
    padding: 0.52rem 1rem !important;
  }
  .content_main {
    .content_timeline {
      max-height: 450px;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
    .content_form {
      display: flex;
      justify-content: center;
    }
  }
  .select-obra .vs__dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
  }
  .mt-02 {
    margin-top: 0.2rem;
  }
  </style>
  